import React from 'react';

export default function PharmacySearch({
  refProp,
  activeType,
  setActiveType,
  search,
  handleSearchChange,
  selectOption,
  valueList,
}) {
  return (
    <div className='pharmacy-search'>
      <div className='pharmacy-search-types'>
        <div
          className={activeType === 'name' ? 'active type' : 'type'}
          onClick={() => setActiveType('name')}
        >
          <button>Name</button>
        </div>
        <div
          className={
            activeType === 'address' ||
            activeType === 'auto-complete-pharmacies'
              ? 'active type'
              : 'type'
          }
          onClick={() => setActiveType('address')}
        >
          <button>Addresse</button>
        </div>
      </div>
      <div className='pharmacy-search-bar'>
        <input
          type='search'
          name='pharmacy-search'
          id='pharmacy-search'
          value={search}
          onChange={handleSearchChange}
          disabled={selectOption === 'all' ? true : false}
        />
      </div>
      <div
        ref={refProp}
        className='search-list'
        style={valueList ? {} : { display: 'none' }}
      >
        {valueList}
      </div>
    </div>
  );
}
