import React, { useEffect, useState } from 'react';
import Dropdown from './dropdown/Dropdown.tsx';
import { getAllSymptomsList } from '../../../utils/api.ts';

interface Symptom {
  value: string;
}

export default function Symptoms({ sessionSymptoms, setSymptoms }) {
  const [symptomsList, setSymptomsList] = useState<Symptom[]>();

  useEffect(() => {
    const fetchSymptomsList = async () => {
      const symptoms = await getAllSymptomsList();
      setSymptomsList(symptoms);
    };

    fetchSymptomsList();
  }, []);

  return symptomsList === undefined ? (
    <div></div>
  ) : (
    <Dropdown
      name={'Symptome'}
      sourceValues={symptomsList}
      sessionValues={sessionSymptoms}
      setSessionValues={setSymptoms}
    ></Dropdown>
  );
}
