import axios from "axios";
import config from "../config/config.ts";
import { DEFAULT_FILTER, FilterI } from "../interfaces/filter.ts";
import IPharmacy from "../interfaces/pharmacy.ts";

export const applyFilters = async (filter: FilterI, currentPharmacy: IPharmacy | undefined, pharmacySelectMethod?: string, pharmacySearchTerm?: string) => {
  const preparedFilter = {};

  Object.keys(filter).forEach((key) => {
    if (
      filter[key] === null
      || filter[key] === ""
      || filter[key] === undefined
      || (Array.isArray(filter[key]) && filter[key].length === 0)
    ) {
      return;
    }

    preparedFilter[key] = filter[key];
  });

  if (currentPharmacy?.id) {
    preparedFilter['pharmacyId'] = currentPharmacy.id;
  } else {
    preparedFilter['pharmacyId'] = "";
  }

  const pharmacyMeta = {};
  if (pharmacySelectMethod) {
    pharmacyMeta['pharmacySelectMethod'] = pharmacySelectMethod;
  }
  if (pharmacySearchTerm) {
    pharmacyMeta['pharmacySearchTerm'] = pharmacySearchTerm;
  }
  if (currentPharmacy?.location) {
    pharmacyMeta['pharmacyLocation'] = currentPharmacy.location;
  }

  if (Object.keys(pharmacyMeta).length > 0) {
    preparedFilter['pharmacyMeta'] = pharmacyMeta;
  }

  await axios
    .post(
      `${config.server}/miniApp/filter/apply`,
      JSON.stringify({
        telegramUserId: window?.Telegram?.WebApp?.initDataUnsafe?.user?.id,
        filters: { ...preparedFilter },
      }),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
}

export const getSessionFilter = async () => {
  const response = await axios
    .get(
      `${config.server}/miniApp/filter/getSessionFilters`,
      {
        params: {
          telegramUserId: window?.Telegram?.WebApp?.initDataUnsafe?.user?.id,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

  const filter: FilterI = DEFAULT_FILTER;
  let pharmacyId: string = "";

  if (!response?.data?.sessionFilters) {
    return { filter, pharmacyId };
  }

  Object.keys(response.data.sessionFilters).forEach((key) => {
    filter[key] = response.data.sessionFilters[key];

    if (key === 'pharmacyId') {
      pharmacyId = response.data.sessionFilters[key];
    }
  });

  return { filter, pharmacyId };
}

export const getMaxBudPrice = async () => {
  const response = await axios
    .get(`${config.server}/miniApp/bud/getMaxPrice`);

  return response.data;
}

export const getAllCultivarsList = async () => {
  const response = await axios
    .get(`${config.server}/miniApp/cultivar/getAvailable`);

  return response.data;
}

export const getAllEffectsList = async () => {
  const response = await axios
    .get(`${config.server}/miniApp/effect/getAvailable`);

  return response.data;
}

export const getAllSymptomsList = async () => {
  const response = await axios
    .get(`${config.server}/miniApp/symptom/getAvailable`);

  return response.data;
}

export const getAllTastesList = async () => {
  const response = await axios
    .get(`${config.server}/miniApp/taste/getAvailable`);

  return response.data;
}

export const getAllTerpenesList = async () => {
  const response = await axios
    .get(`${config.server}/miniApp/terpene/getAvailable`);

  return response.data;
}

export const getAllOriginGrowersList = async () => {
  const response = await axios
    .get(`${config.server}/miniApp/originGrower/getAvailable`);

  return response.data;
}

export const getAllOriginCountriesList = async () => {
  const response = await axios
    .get(`${config.server}/miniApp/originCountry/getAvailable`);

  return response.data;
}

export const getAllManufacturersList = async () => {
  const response = await axios
    .get(`${config.server}/miniApp/manufacturer/getAvailable`);

  return response.data;
}

export const getAllPharmacies = async () => {
  const response = await axios
    .get(`${config.server}/miniApp/pharmacy/getAvailable`);

  return response.data;
}

export async function searchPharmacies(
  type: 'name' | 'address' | 'auto-complete-pharmacies',
  searchTerm: string,
) {
  console.log(searchTerm)
  const response: PharmacySearchResult = await axios.get(`${config.server}/miniApp/pharmacy/search?type=${type}&searchTerm=${searchTerm}`);
  return response;
}   

export type PharmacySearchResult =  {
  type: string;
  data: {
      id: string;
      title: string;
      location: string;
      distance: number;
  }[];
}