export interface FilterI {
  price: [number, number];
  thc: [number, number];
  cbd: [number, number];
  genetic?: string;
  treatment?: string;
  cultivar?: string[];
  terpenes?: string[];
  effects?: string[];
  originGrower?: string[];
  originCountry?: string[];
  manufacturer?: string[];
  applications?: string[];
  taste?: string[];
  search?: string;
}

export interface SessionFilterI {
  id: string,
  filterId: string,
  filterName: string,
  filterValue: string,
}

export let DEFAULT_MAX_PRICE = 30;
export const DEFAULT_MAX_THC = 32.1;
export const DEFAULT_MAX_CBD = 21;
export const DEFAULT_PRICE_STEP = 0.01;

const THC_RANGE = [0, DEFAULT_MAX_THC];
const CBD_RANGE = [0, DEFAULT_MAX_CBD];
const PRICE_RANGE = [0, DEFAULT_MAX_PRICE];

export const DEFAULT_FILTER: FilterI = {
  price: PRICE_RANGE as [number, number],
  thc: THC_RANGE as [number, number],
  cbd: CBD_RANGE as [number, number],
  genetic: "",
  treatment: "",
  cultivar: [] as string[],
  terpenes: [] as string[],
  effects: [] as string[],
  originGrower: [] as string[],
  originCountry: [] as string[],
  manufacturer: [] as string[],
  applications: [] as string[],
  taste: [] as string[],
  search: "",
};
