'use client';
import { useCallback, useState } from 'react';
import { DEFAULT_FILTER, DEFAULT_MAX_CBD, DEFAULT_MAX_PRICE, DEFAULT_MAX_THC, FilterI } from '../interfaces/filter.ts';

export type UseFilterOutput = ReturnType<typeof useFilter>;

export function useFilter() {
  const [filter, setFilter] = useState(DEFAULT_FILTER);

  const setPrice = useCallback(
    (price: FilterI['price']) => setFilter({ ...filter, price }),
    [filter]
  );
  const setThc = useCallback(
    (thc: FilterI['thc']) => setFilter({ ...filter, thc }),
    [filter]
  );
  const setCbd = useCallback(
    (cbd: FilterI['cbd']) => setFilter({ ...filter, cbd }),
    [filter]
  );
  const setGenetic = useCallback(
    (genetic: FilterI['genetic']) => {
      setFilter({ ...filter, genetic: genetic ?? "" });
    },
    [filter]
  );
  const setTreatment = useCallback(
    (treatment: FilterI['treatment']) => {
      setFilter({ ...filter, treatment: treatment ?? "" });
    },
    [filter]
  );
  const setCultivar = useCallback(
    (cultivar: FilterI['cultivar']) => {
      setFilter({ ...filter, cultivar: cultivar ?? [] });
    },
    [filter]
  );

  const setEffects = useCallback(
    (effects: FilterI['effects']) => {
      setFilter({ ...filter, effects: effects ?? [] });
    },
    [filter]
  );

  const setSymptoms = useCallback(
    (applications: FilterI['applications']) => {
      setFilter({ ...filter, applications: applications ?? [] });
    },
    [filter]
  );

  const setTerpenes = useCallback(
    (terpenes: FilterI['terpenes']) => {
      setFilter({ ...filter, terpenes: terpenes ?? [] });
    },
    [filter]
  );

  const setTastes = useCallback(
    (taste: FilterI['taste']) => {
      setFilter({ ...filter, taste: taste ?? [] });
    },
    [filter]
  );

  const setOriginGrowers = useCallback(
    (originGrower: FilterI['originGrower']) => {
      setFilter({ ...filter, originGrower: originGrower ?? [] });
    },
    [filter]
  );

  const setOriginCountries = useCallback(
    (originCountry: FilterI['originCountry']) => {
      setFilter({ ...filter, originCountry: originCountry ?? [] });
    },
    [filter]
  );

  const setManufacturers = useCallback(
    (manufacturer: FilterI['manufacturer']) => {
      setFilter({ ...filter, manufacturer: manufacturer ?? [] });
    },
    [filter]
  );

  const resetAllFilters = useCallback(
    () => {
      setFilter({
        ...DEFAULT_FILTER,
        price: [0, DEFAULT_MAX_PRICE],
        thc: [0, DEFAULT_MAX_THC],
        cbd: [0, DEFAULT_MAX_CBD],
        genetic: "",
        treatment: "",
        cultivar: [],
        terpenes: [],
        effects: [],
        originGrower: [],
        originCountry: [],
        manufacturer: [],
        applications: [],
        taste: [],
        // Not resetting search on purpose, because it is reseted from TG /reset command
        // search: "",
      });
    },
    [filter]
  );

  return {
    filter,
    setFilter,
    setPrice,
    setThc,
    setCbd,
    setGenetic,
    setCultivar,
    setSymptoms,
    setTerpenes,
    setTreatment,
    setEffects,
    setTastes,
    setOriginGrowers,
    setOriginCountries,
    setManufacturers,
    resetAllFilters,
  };
}
