import React, { useEffect, useState } from 'react';
import Dropdown from './dropdown/Dropdown.tsx';
import { getAllCultivarsList } from '../../../utils/api.ts';

interface Cultivar {
  value: string;
}

export default function Cultivars({ sessionCultivars, setCultivars }) {
  const [cultivarsList, setCultivarsList] = useState<Cultivar[]>();

  useEffect(() => {
    const fetchCultivarsList = async () => {
      const cultivars = await getAllCultivarsList();
      setCultivarsList(cultivars);
    };

    fetchCultivarsList();
  }, []);

  return cultivarsList === undefined ? (
    <div></div>
  ) : (
    <Dropdown
      name={'Kultivar'}
      sourceValues={cultivarsList}
      sessionValues={sessionCultivars}
      setSessionValues={setCultivars}
    ></Dropdown>
  );
}
