"use client";
import { useEffect, useState } from "react";
import { useDebounceValue } from "usehooks-ts";
import { PharmacySearchResult, searchPharmacies } from "../utils/api.ts";

export const SEARCH_TYPES = [
  "name",
  "address",
  "auto-complete-pharmacies",
] as const;

export type PharmacySearchType = (typeof SEARCH_TYPES)[number];

export function usePharmaciesSearch() {
  const [searchResults, setSearchResults] = useState<PharmacySearchResult>({
    type: "name",
    data: [],
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] =
    useState<PharmacySearchType>("name");
  const debouncedSearchTerm = useDebounceValue(searchTerm, 1000);
  const debouncedSelectedOption = useDebounceValue(selectedOption, 1000);

  useEffect(() => {
    searchPharmacies(debouncedSelectedOption[0], debouncedSearchTerm[0])
      .then((result) => {setSearchResults({type: result.data.type, data: result.data.data})})
      .catch(console.error);
  }, [debouncedSelectedOption[0], debouncedSearchTerm[0]]);

  return {
    searchResults,
    selectedOption,
    searchTerm,
    setSearchTerm,
    setSelectedOption,
  };
}