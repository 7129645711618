import { createBrowserRouter } from 'react-router-dom';
import Main from '../components/main/Main.tsx';
import { Layout } from './Layout.tsx';
import React from 'react';
import { Buds } from '../components/buds/Buds.tsx';
import { Pharmacies } from '../components/pharmacies/Pharmacies.tsx';

export const routers = createBrowserRouter([
  
  {
    element: <Layout />,
    children: [
      { path: '/', element: <Main /> },
      { path: '/buds', element: <Buds /> },
      { path: '/pharmacies', element: <Pharmacies /> },
    ],
  },
]);
