import React, { useEffect, useState } from 'react';
import Dropdown from './dropdown/Dropdown.tsx';
import { getAllEffectsList } from '../../../utils/api.ts';

interface Effect {
  value: string;
}

export default function Effects({ sessionEffects, setEffects }) {
  const [effectsList, setEffectsList] = useState<Effect[]>();

  useEffect(() => {
    const fetchEffectsList = async () => {
      const effects = await getAllEffectsList();
      setEffectsList(effects);
    };

    fetchEffectsList();
  }, []);

  return effectsList === undefined ? (
    <div></div>
  ) : (
    <Dropdown
      name={'Wirkungen'}
      sourceValues={effectsList}
      sessionValues={sessionEffects}
      setSessionValues={setEffects}
    ></Dropdown>
  );
}
