import React, { useEffect, useState } from 'react';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import { routers } from './router/router.tsx';
import { Pharmacy } from './components/pharmacies/Pharmacies.tsx';
import { UseFilterOutput, useFilter } from './hooks/use-filter.ts';
import axios from 'axios';
import config from './config/config.ts';

export const PharmacyContext = React.createContext<{
  currentPharmacy: Pharmacy | undefined;
  setCurrentPharmacy;
  sourcePharmacies: Pharmacy[] | undefined;
  setSourcePharmacies;
}>({
  currentPharmacy: undefined,
  setCurrentPharmacy: undefined,
  sourcePharmacies: undefined,
  setSourcePharmacies: undefined,
});

export const LocationContext = React.createContext({
  lat: 51,
  lng: 10,
});

export const FilterContext = React.createContext<UseFilterOutput | null>(null);

function App() {
  const [currentPharmacy, setCurrentPharmacy] = useState<Pharmacy>();
  const [sourcePharmacies, setSourcePharmacies] = useState<Pharmacy[]>([]);
  const [location, setLocation] = useState({ lat: 51, lng: 10 });

  useEffect(() => {
    const onSuccess = (position) => {
      setLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    };

    const onError = (error) => {
      console.log(error.message);
    };

    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  }, []);

  useEffect(() => {
    axios.post(`${config.server}/miniApp/user/setCoordinates`, {
      userId: window?.Telegram?.WebApp?.initDataUnsafe?.user?.id,
      lng: location.lng,
      lat: location.lat,
    });
  }, [location.lat, location.lng]);

  return (
    <div className='App'>
      <FilterContext.Provider value={useFilter()}>
        <LocationContext.Provider value={location}>
          <PharmacyContext.Provider
            value={{
              currentPharmacy,
              setCurrentPharmacy,
              sourcePharmacies,
              setSourcePharmacies,
            }}
          >
            <RouterProvider router={routers} />
          </PharmacyContext.Provider>
        </LocationContext.Provider>
      </FilterContext.Provider>
    </div>
  );
}

export default App;
