import React from "react";

interface FilterButtonProps {
    title: string;
    isActive: boolean;
    setAction: () => void;
  }
  
  export function FilterButton({
    title,
    isActive,
    setAction,
  }: FilterButtonProps) {
    return (
      <span
        className={`filter-box-item ${
          isActive ? "active" : ""
        }`}
        onClick={setAction}
      >
        {title}
      </span>
    );
  }