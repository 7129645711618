import React from 'react';
import { FilterButton } from './FilterButton.tsx';

export function Treatments({ treatment, setTreatment }) {
  return (
    <div className='filter-box filter-treatment'>
      <p className='filter-box-name'>Bestrahlung</p>
      <div className='filter-treatment-buttons'>
        <FilterButton
          title='bestrahlt'
          isActive={treatment === 'bestrahlt'}
          setAction={() =>
            treatment === 'bestrahlt'
              ? setTreatment(null)
              : setTreatment('bestrahlt')
          }
        />
        <FilterButton
          title='unbestrahlt'
          isActive={treatment === 'unbestrahlt'}
          setAction={() =>
            treatment === 'unbestrahlt'
              ? setTreatment(null)
              : setTreatment('unbestrahlt')
          }
        />
      </div>
    </div>
  );
}
