import React, { useEffect, useState } from 'react';
import Dropdown from './dropdown/Dropdown.tsx';
import { getAllManufacturersList } from '../../../utils/api.ts';

interface Manufacturer {
  value: string;
}

export default function Manufacturers({ sessionManufacturers, setManufacturers }) {
  const [manufacturersList, setManufacturersList] = useState<Manufacturer[]>();

  useEffect(() => {
    const fetchManufacturersList = async () => {
      const manufacturers = await getAllManufacturersList();
      setManufacturersList(manufacturers);
    };

    fetchManufacturersList();
  }, []);

  return manufacturersList === undefined ? (
    <div></div>
  ) : (
    <Dropdown
      name={'Hersteller'}
      sourceValues={manufacturersList}
      sessionValues={sessionManufacturers}
      setSessionValues={setManufacturers}
    ></Dropdown>
  );
}
