import React, { useEffect, useState } from 'react';
import Dropdown from './dropdown/Dropdown.tsx';
import { getAllTerpenesList } from '../../../utils/api.ts';

interface Terpene {
  value: string;
}

export default function Terpenes({ sessionTerpenes, setTerpenes }) {
  const [terpenesList, setTerpenesList] = useState<Terpene[]>();

  useEffect(() => {
    const fetchTerpenesList = async () => {
      const terpenes = await getAllTerpenesList();
      setTerpenesList(terpenes);
    };

    fetchTerpenesList();
  }, []);

  return terpenesList === undefined ? (
    <div></div>
  ) : (
    <Dropdown
      name={'Terpene'}
      sourceValues={terpenesList}
      sessionValues={sessionTerpenes}
      setSessionValues={setTerpenes}
    ></Dropdown>
  );
}
