import React from 'react';
import './Modal.scss';

export default function Modal({ isVisible, changeIsVisible }) {
  return isVisible ? (
    <div className='modal-overlay' onClick={() => changeIsVisible(false)}>
      <div className='modal-container'>
        <p>Sharing your link with friends on the Telegram bot</p>
        <div className='plus'>
          <img src='plus-bullet.svg' alt='PlusBullet' />
          <p>not only earns you rewards like discounts or credits </p>
        </div>
        <div className='plus'>
          <img src='plus-bullet.svg' alt='PlusBullet' />
          <p>
            but also enhances the bot's functionality and fosters a vibrant
            community
          </p>
        </div>
        <p>
          Additionally, it helps extend the bot's reach and supports its ongoing
          growth and development.
        </p>
      </div>
    </div>
  ) : null;
}
