import React from 'react';
import './ActionButtons.scss'
import { Link } from 'react-router-dom';

export default function ActionButtons({ resetAllFilters }) {
  return (
    <div id='action-buttons'>
      <div className='back-button'>
      <Link to={'/'}>
        <img src="start.svg" alt="Back" />
        Start
      </Link>
      </div>
      <div className='reset-button' onClick={ () =>  void resetAllFilters() }>
        <img src="cancel.svg" alt="Cancel" />
        <span>Zurücksetzen</span>
      </div>
    </div>
  );
}
