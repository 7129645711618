import React from 'react';
import './PharmacyNotFound.scss';

export default function PharmacyNotFound() {
  return (
    <div className='search-list-option-notfound'>
      <div className='notfound-icon'>
        <img src='pharmacy-not-found.svg' alt='Pharmacy Not Found' />
      </div>
      <div className='notfound-text'>
        Wir haben keine passende Adresse zu deinen Angaben gefunden. Versuche
        bitte deine Angaben zu korrigieren! Oder verklage bitte Google.
      </div>
    </div>
  );
}
