import React, { useContext, useEffect, useState } from 'react';
import { Pharmacy } from '../Pharmacies';
import './PharmacyInfo.scss';
import config from '../../../config/config.ts';
import { LocationContext } from '../../../App.tsx';
import { calculateDistance } from '../../../utils/geo.ts';

export default function PharmacyInfo({
  pharmacy,
  pharmacyInfo,
}: {
  pharmacy?: Pharmacy;
  pharmacyInfo: { pharmacyCount };
}) {
  const [budsAndExtractsCount, setBudsAndExtractsCount] = useState({
    budsCount: 0,
    extractsCount: 0,
  });

  const location = useContext(LocationContext);
  const distance = Math.floor(calculateDistance(location, {
    lat: Number(pharmacy?.lat),
    lng: Number(pharmacy?.lng),
  }));

  useEffect(() => {
    async function fetchBudsCount() {
      const fetchedBudsCount = await fetch(
        `${config.server}/miniApp/pharmacies/getTotalProducts?id=${pharmacy?.id}`
      );
      const budsAndExtractsCount = await fetchedBudsCount.json();
      setBudsAndExtractsCount(budsAndExtractsCount);
    }
    if (pharmacy) {
      fetchBudsCount();
    }
  }, [pharmacy]);

  if (!pharmacy) {
    return (
      <div className='pharmacy-info'>
        <p>{pharmacyInfo.pharmacyCount} Apotheken</p>
      </div>
    );
  }
  return (
    <div className='pharmacy-info'>
      <h2 className='info-title'>
        {pharmacy.title}
        <span className='info-distance'> ({distance} km)</span>
      </h2>
      <div className='info-working-hours'>
        <div className='working-hours-icon'>
          <img src='clock.svg' alt='Working Hours Icon' />
        </div>
        <div className='working-hours-text'>
          <p>{pharmacy.workingHoursWeekdays}</p>
          <p>{pharmacy.workingHoursSaturday}</p>
        </div>
      </div>
      <div className='info-availability'>
        <div className='availability-icon'>
          <img src='available.svg' alt='Available Icon' />
        </div>
        <p className='info-buds'>
          Momentan sind in der Apotheke {budsAndExtractsCount.budsCount} Blüten
          und {budsAndExtractsCount.extractsCount} Extrakte verfügbar
        </p>
      </div>
    </div>
  );
}
