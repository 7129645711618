import { FilterButton } from './FilterButton.tsx';
import React from 'react';
import { Dominance } from './Dominance.tsx';

export function Genetics({ genetic, setGenetic }) {
  return (
    <div className='filter-box filter-genetik'>
      <p className='filter-box-name'>genetik</p>
      <div className='filter-genetik-buttons'>
        <FilterButton
          title='Sativa'
          isActive={genetic === 'Sativa'}
          setAction={() =>
            genetic === 'Sativa' ? setGenetic(null) : setGenetic('Sativa')
          }
        />
        <FilterButton
          title='Indica'
          isActive={genetic === 'Indica'}
          setAction={() =>
            genetic === 'Indica' ? setGenetic(null) : setGenetic('Indica')
          }
        />
        <FilterButton
          title='Hybrid'
          isActive={genetic?.startsWith('Hybrid') ?? false}
          setAction={() =>
            genetic?.startsWith('Hybrid')
              ? setGenetic(null)
              : setGenetic('Hybrid')
          }
        />
      </div>
      <Dominance genetic={genetic} setGenetic={setGenetic}/>
    </div>
  );
}
