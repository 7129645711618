type Point = {
    lat: number;
    lng: number;
  };
  
  const EARTH_RADIUS = 6371;
  const INVERSE_RADIAN = Math.PI / 180;
  
  function toRadians(degrees: number) {
    return degrees * INVERSE_RADIAN;
  }
  
  /**
   * @description The function for calculating distance between two points
   * using the Haversine formula.
   * @param x Represents the first point's latitude and longitude.
   * @param y Represents the second point's latitude and longitude.
   * @returns The distance between point x and point y in kilometers
   * @see[Haversine formula]{@link https://en.wikipedia.org/wiki/Haversine_formula}
   */
  export function calculateDistance(x: Point, y: Point) {
    const xLat = toRadians(x.lat);
    const yLat = toRadians(y.lat);
    const deltaLat = toRadians(y.lat - x.lat);
    const deltaLng = toRadians(y.lng - x.lng);
    const hav =
      Math.sin(deltaLat / 2) ** 2 +
      Math.cos(xLat) * Math.cos(yLat) * Math.sin(deltaLng / 2) ** 2;
    const ahav = 2 * Math.atan2(Math.sqrt(hav), Math.sqrt(1 - hav));
    return EARTH_RADIUS * ahav;
  }