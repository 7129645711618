import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import React from 'react';
import './Price.scss';
import { DEFAULT_MAX_PRICE, DEFAULT_PRICE_STEP } from '../../../interfaces/filter.ts';

export function Price({ price, maxPrice, setPrice }) {
  return DEFAULT_MAX_PRICE !== maxPrice && (
    <div className='filter-box filter-price'>
      <div className='filter-box-slider'>
        <p className='filter-box-name'>Preis</p>
        <RangeSlider
          id='range-slider-gradient'
          className='margin-lg'
          value={price}
          min={0}
          max={maxPrice}
          step={DEFAULT_PRICE_STEP}
          onInput={setPrice}
        />
      </div>
      <div className='filter-box-values'>
        <div className='filter-box-value'>{price[0]}</div>
        <div className='filter-box-value'>{price[1] === maxPrice ? maxPrice : price[1]}</div>
      </div>
    </div>
  )
}
