import React from 'react';

export default function DropdownItem({ item, setValues, isChecked }) {
  return (
    <div className='dropdown-value'>
      <div className='dropdown-value-select'>
        <input
          type='checkbox'
          checked={isChecked}
          id={item.value}
          onChange={() => {
            !isChecked
              ? setValues((prev) => [...prev, item.value])
              : setValues((prev) => prev.filter((p) => p !== item.value));
          }}
        />
        <span className='checkmark'></span>
        <label htmlFor={item.value}>{item.label}</label>
      </div>
    </div>
  );
}
