import React, { useContext, useEffect, useState } from 'react';
import './Main.scss';
import PageButton from './page-button/PageButton.tsx';
import { Link } from 'react-router-dom';
import config from '../../config/config.ts';
import { PharmacyContext } from '../../App.tsx';
import Modal from './modal/Modal.tsx';
import { getAllPharmacies, getSessionFilter } from '../../utils/api.ts';
import { useFilter } from '../../hooks/use-filter.ts';

// interface BudsInfo {
//   budsCount: number;
//   countryCount: number;
//   manufacturerCount: number;
// }

export default function Main() {
  const [budsInfo, setBudsInfo] = useState({
    budsCount: 0,
    countryCount: 0,
    manufacturerCount: 0,
  });

  const { filter, setFilter } = useFilter();

  const [refCount, setRefCount] = useState(0);

  const [pharmacyInfo, setPharmacyInfo] = useState({
    pharmacyCount: 0,
  });
  const { currentPharmacy, setCurrentPharmacy, sourcePharmacies, setSourcePharmacies } = useContext(PharmacyContext);

  const [isModalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    async function fetchInfo() {
      const [budsInfoFetched, pharmacyInfoFetched, refCountFetched, { filter: retrievedFilter, pharmacyId }, sourcePharmacies] =
        await Promise.all([
          fetch(`${config.server}/miniApp/bud/getSummary`),
          fetch(`${config.server}/miniApp/pharmacy/getSummary`),
          fetch(
            `${config.server}/miniApp/user/getTotalReferrals?id=${window?.Telegram?.WebApp?.initDataUnsafe?.user?.id}`
          ),
          await getSessionFilter(),
          await getAllPharmacies(),
        ]);

      const [budsInfo, pharmacyInfo, refCount] = await Promise.all([
        budsInfoFetched.json(),
        pharmacyInfoFetched.json(),
        refCountFetched.json(),
      ]);

      setBudsInfo(budsInfo);
      setPharmacyInfo(pharmacyInfo);
      setRefCount(refCount);
      setFilter(retrievedFilter);
      setSourcePharmacies(sourcePharmacies);

      if (pharmacyId && sourcePharmacies) {
        const selectedPharmacy = sourcePharmacies?.find((pharmacy) => pharmacy.id === pharmacyId);
        if (selectedPharmacy) {
          setCurrentPharmacy(selectedPharmacy);
        }
      }
    }

    fetchInfo();
  }, []);

  useEffect(() => {
    setSourcePharmacies(sourcePharmacies);
  }, [sourcePharmacies]);

  const referalLink = `t.me/GreensbyTestBot?start=refer_${window?.Telegram?.WebApp?.initDataUnsafe?.user?.id}`;

  return (
    <div id='main'>
      <Modal isVisible={isModalVisible} changeIsVisible={setModalVisible} />
      <div className='caption'>
        <div className='caption-header'>
          <div className='caption-header-icon'>
            <img src='Filter.svg' alt='Filter icon' />
          </div>
          <div className='caption-header-text'>
            <h1>Wonach suchst Du?</h1>
          </div>
        </div>
        <div className='caption-text'>
          <p>
            Hier findest Du alle Cannabis-Produkte, die aktuell auf dem
            deutschen Markt verfügbar sind. Passe die Filter an, um die Auswahl
            zu verfeinern
          </p>
        </div>
      </div>
      <div className='main-buttons'>
        <Link to={'/buds'}>
          <PageButton
            name={'Blüten'}
            className={'bluten'}
            budsInfo={budsInfo}
            filter={filter}
          />
        </Link>
        <PageButton name={'Extrakte'} className={'extract not-available'} />
        <Link
          to={'/pharmacies'}
          state={{ pharmacyInfo: pharmacyInfo.pharmacyCount }}
        >
          <PageButton
            name={'Apotheke'}
            className={'pharmacy'}
            pharmacyInfo={pharmacyInfo}
            pharmacy={currentPharmacy}
            filter={filter}
          />
        </Link>
      </div>
      <div className='main-referral'>
        <div className='main-referral-link'>
          <div
            className='input'
            onClick={() => {
              window.navigator.clipboard.writeText(referalLink);
              alert('Referal link succesfully copied');
            }}
          >
            {/* <input
              type='text'
              value={referalLink}
              id='referral-input'
              disabled
            /> */}
            <span id='referral-input'>{referalLink}</span>
            <img src='copy.svg' alt='Copy' className='copy-icon' />
          </div>
          <img
            src='info.svg'
            alt='Information'
            className='info-icon'
            onClick={() => {
              setModalVisible(true);
            }}
          />
        </div>
        <Link
          to={`https://t.me/share/url?url=https://t.me/GreensbyTestBot?start=refer_${window?.Telegram?.WebApp?.initDataUnsafe?.user?.id}`}
          className='main-referral-telegram'
        >
          <input type='button' value='Einladungslink mit Freunden teilen' />
          <img src='telegram.svg' alt='Telegram' className='telegram-icon' />
        </Link>
        <div className='main-referral-count'>
          <p>Freunde beigetreten: {refCount}</p>
        </div>
      </div>
    </div>
  );
}
