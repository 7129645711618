import React, { useContext, useEffect, useMemo, useState } from 'react';
import './Buds.scss';
import { Filter as FilterPanel } from './filter/Filter.tsx';
import { DEFAULT_MAX_PRICE } from '../../interfaces/filter.ts';
import { applyFilters, getMaxBudPrice } from '../../utils/api.ts';
import { FilterContext, PharmacyContext } from '../../App.tsx';

export function Buds() {
  // const [activeTab, setActiveTab] = useState<'filter' | 'pharmacy'>('filter');
  const {
    filter,
    setPrice,
    setThc,
    setCbd,
    setGenetic,
    setCultivar,
    setSymptoms,
    setTerpenes,
    setTastes,
    setTreatment,
    setEffects,
    setOriginGrowers,
    setOriginCountries,
    setManufacturers,
    resetAllFilters
  } = useContext(FilterContext)!;

  const { currentPharmacy } = useContext(PharmacyContext);

  const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(true);

  const [maxBudPrice, setMaxBudPrice] = useState(DEFAULT_MAX_PRICE);

  useMemo(() => {
    async function fetchMaxBudPrice() {
      const maxPrice = await getMaxBudPrice();
      setMaxBudPrice(parseFloat(maxPrice));

      if (filter.price[1] === DEFAULT_MAX_PRICE) {
        setPrice([0, parseFloat(maxPrice)]);
      }
    }

    fetchMaxBudPrice();
  }, []);

  useEffect(() => {
    setIsApplyButtonDisabled(false);
  }, [filter]);

  return (
    <div>
      <div className='container'>
        <div className='caption'>
          <div className='caption-header'>
            <div className='caption-header-text'>
              <h1>Wonach suchst Du?</h1>
            </div>
          </div>
          <div className='caption-text'>
            <p>
            Hier findest Du alle Cannabis-Produkte, die aktuell auf dem
            deutschen Markt verfügbar sind. Passe die Filter an, um die Auswahl
            zu verfeinern
            </p>
          </div>
        </div>
        <FilterPanel
          filter={filter}
          setPrice={setPrice}
          setThc={setThc}
          setCbd={setCbd}
          setGenetic={setGenetic}
          setTreatment={setTreatment}
          resetAllFilters={resetAllFilters}
          maxPrice={maxBudPrice}
          setCultivar={setCultivar}
          setSymptoms={setSymptoms}
          setTerpenes={setTerpenes}
          setEffects={setEffects}
          setTastes={setTastes}
          setOriginGrowers={setOriginGrowers}
          setOriginCountries={setOriginCountries}
          setManufacturers={setManufacturers}
        />

        <button
          id='apply-button'
          disabled={isApplyButtonDisabled}
          onClick={ async () => {
            await applyFilters(filter, currentPharmacy)
          } }
        >
          Anwenden
        </button>
      </div>
    </div>
  );
}
