import 'react-range-slider-input/dist/style.css';
import React from 'react';

export function Dominance({ genetic, setGenetic }) {
  return (
    genetic?.startsWith('Hybrid') && (
      <div className='pharm-radio-options'>
        <p className='filter-box-name'>dominanz</p>
        <div className='dominance'>
          <div className='dominance-option'>
            <input
              type='radio'
              name='dominance'
              onClick={() => setGenetic('Hybrid-Sativa')}
              id='radion-hybrid-sativa'
            />
            <span
              className={`dominance-radio-option ${
                genetic === 'Hybrid-Sativa' ? 'active' : ''
              }`}
              onClick={() => setGenetic('Hybrid-Sativa')}
              id='radion-hybrid-sativa'
            ></span>
            <label htmlFor='radion-hybrid-sativa'>Sativa</label>
          </div>
          <div className='dominance-option'>
            <input
              type='radio'
              name='dominance'
              onClick={() => setGenetic('Hybrid-Indica')}
              id='radion-hybrid-indica'
            />
            <span
              className={`dominance-radio-option ${
                genetic === 'Hybrid-Indica' ? 'active' : ''
              }`}
              onClick={() => setGenetic('Hybrid-Indica')}
              id='radion-hybrid-indica'
            ></span>
            <label
              htmlFor='radion-hybrid-indica'
              className='pharm-radio-option-title'
            >
              Indica
            </label>
          </div>
        </div>
      </div>
    )
  );
}
