import React, { useEffect, useState } from 'react';
import Dropdown from './dropdown/Dropdown.tsx';
import { getAllOriginGrowersList } from '../../../utils/api.ts';

interface OriginGrower {
  value: string;
}

export default function OriginGrowers({ sessionOriginGrowers, setOriginGrowers }) {
  const [originGrowersList, setOriginGrowersList] = useState<OriginGrower[]>();

  useEffect(() => {
    const fetchOriginGrowersList = async () => {
      const originGrowers = await getAllOriginGrowersList();
      setOriginGrowersList(originGrowers);
    };

    fetchOriginGrowersList();
  }, []);

  return originGrowersList === undefined ? (
    <div></div>
  ) : (
    <Dropdown
      name={'Anbauer'}
      sourceValues={originGrowersList}
      sessionValues={sessionOriginGrowers}
      setSessionValues={setOriginGrowers}
    ></Dropdown>
  );
}
