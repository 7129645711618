import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../components/header/Header.tsx';
import './Layout.scss';

export const Layout = () => (
  <>
    <Header />
    <Outlet />
  </>
);
