import { Genetics } from './Genetics.tsx';
import { Price } from '../price/Price.tsx';
import { Treatments } from './Treatments.tsx';
import { FilterI } from '../../../interfaces/filter.ts';
import { Thc } from './Thc.tsx';
import { Cbd } from './Cbd.tsx';
import './Filter.scss';
import React from 'react';
import { UseFilterOutput } from '../../../hooks/use-filter.ts';
import Cultivars from './Cultivars.tsx';
import ActionButtons from '../container/ActionButtons.tsx';
import Effects from './Effects.tsx';
import Symptoms from './Symptoms.tsx';
import Terpenes from './Terpenes.tsx';
import Tastes from './Tastes.tsx';
import OriginGrowers from './OriginGrowers.tsx';
import OriginCountries from './OriginCountries.tsx';
import Manufacturers from './Manufacturers.tsx';

interface FilterProps {
  filter: FilterI;
  maxPrice: number;
  setPrice: UseFilterOutput['setPrice'];
  setThc: UseFilterOutput['setThc'];
  setCbd: UseFilterOutput['setCbd'];
  setGenetic: UseFilterOutput['setGenetic'];
  setTreatment: UseFilterOutput['setTreatment'];
  setCultivar: UseFilterOutput['setCultivar'];
  setEffects: UseFilterOutput['setEffects'];
  setSymptoms: UseFilterOutput['setSymptoms'];
  setTerpenes: UseFilterOutput['setTerpenes'];
  setTastes: UseFilterOutput['setTastes'];
  setOriginGrowers: UseFilterOutput['setOriginGrowers'];
  setOriginCountries: UseFilterOutput['setOriginCountries'];
  setManufacturers: UseFilterOutput['setManufacturers'];
  resetAllFilters: UseFilterOutput['resetAllFilters'];
}

export function Filter({
  filter,
  maxPrice,
  setPrice,
  setThc,
  setCbd,
  setGenetic,
  setTreatment,
  setCultivar,
  setSymptoms,
  setEffects,
  setTerpenes,
  setTastes,
  setOriginGrowers,
  setOriginCountries,
  setManufacturers,
  resetAllFilters,
}: FilterProps) {
  return (
    <>
      <ActionButtons resetAllFilters={resetAllFilters} />

      <Price price={filter.price} maxPrice={maxPrice} setPrice={setPrice} />

      <div className='filters'>
        <p className='filter-box-name'>Inhalt</p>
        <Thc thc={filter.thc} setThc={setThc} />
        <Cbd cbd={filter.cbd} setCbd={setCbd} />
        <Genetics genetic={filter.genetic} setGenetic={setGenetic} />
        <Cultivars sessionCultivars={filter.cultivar} setCultivars={setCultivar} />
        <Symptoms sessionSymptoms={filter.applications} setSymptoms={setSymptoms} />
        <Effects sessionEffects={filter.effects} setEffects={setEffects} />
        <Terpenes sessionTerpenes={filter.terpenes} setTerpenes={setTerpenes} />
        <Treatments treatment={filter.treatment} setTreatment={setTreatment} />
        <OriginGrowers sessionOriginGrowers={filter.originGrower} setOriginGrowers={setOriginGrowers} />
        <OriginCountries sessionOriginCountries={filter.originCountry} setOriginCountries={setOriginCountries} />
        <Manufacturers sessionManufacturers={filter.manufacturer} setManufacturers={setManufacturers} />
        <Tastes sessionTastes={filter.taste} setTastes={setTastes} />
      </div>
    </>
  );
}
