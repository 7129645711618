import React, { useContext, useEffect, useRef, useState } from 'react';
import './Pharmacies.scss';
import ActionButtons from '../buds/container/ActionButtons.tsx';
import { useLocation } from 'react-router-dom';
import PharmacyInfo from './pharmacy-info/PharmacyInfo.tsx';
import { FilterContext, PharmacyContext } from '../../App.tsx';
import PharmacyNotFound from './not-found/PharmacyNotFound.tsx';
import PharmacySearch from './pharmacy-search/PharmacySearch.tsx';
import { applyFilters } from '../../utils/api.ts';
import { usePharmaciesSearch } from '../../hooks/use-pharmacies-search.ts';

export interface Pharmacy {
  id: string;
  title: string;
  location: string;
  workingHoursSaturday: string;
  workingHoursWeekdays: string;
  lng: string;
  lat: string;
}

export function Pharmacies() {
  // const [pharmacyList, setPharmacyList] = useState<Pharmacy[]>();
  const [selectedType, setSelectedType] = useState('all');
  const [valueList, setValueList] = useState<
    React.JSX.Element | React.JSX.Element[]
  >();

  const [lastSearchTerm, setLastSearchTerm] = useState('');

  const {
    searchResults,
    selectedOption,
    searchTerm,
    setSearchTerm,
    setSelectedOption,
  } = usePharmaciesSearch();

  const { currentPharmacy, setCurrentPharmacy, sourcePharmacies } =
    useContext(PharmacyContext);

  const [filteredValues, setFilteredValues] = useState<Pharmacy[]>(
    sourcePharmacies ?? []
  );

  const myRef = useRef<HTMLDivElement>(null);

  const { filter } = useContext(FilterContext)!;

  const { state } = useLocation();
  const { pharmacyInfo } = state;

  useEffect(() => {
    if (!searchResults.data) {
      setValueList(undefined);
    } else {
      if (searchResults.data?.length === 0) {
        setValueList(<PharmacyNotFound />);
      } else {
        if (searchResults.type === 'auto-complete') {
          setValueList(
            searchResults.data
              .sort(function (a, b) {
                if (a.title < b.title) {
                  return -1;
                }
                if (a.title > b.title) {
                  return 1;
                }
                return 0;
              })
              .map((value, index) => (
                <div
                  key={index}
                  className='search-list-option'
                  onClick={() => {
                    setSearchTerm(value.title);
                    setSelectedOption('auto-complete-pharmacies');
                  }}
                >
                  {value.title}
                </div>
              ))
          );
        } else if (searchResults.type === 'nearest-pharmacies') {
          setValueList(
            searchResults.data
              .sort(function (a, b) {
                if (a.title < b.title) {
                  return -1;
                }
                if (a.title > b.title) {
                  return 1;
                }
                return 0;
              })
              .map((value, index) => (
                <div
                  key={value.id}
                  className='search-list-option'
                  onClick={() => {
                    setCurrentPharmacy(value);
                    setSelectedOption('address');
                    setSearchTerm(value.title);
                  }}
                >
                  {value.title} ({value.distance.toFixed(2)} km)
                </div>
              ))
          );
        } else if (searchResults.type === 'auto-complete-pharmacies') {
          setValueList(
            searchResults.data
              .sort(function (a, b) {
                if (a.title < b.title) {
                  return -1;
                }
                if (a.title > b.title) {
                  return 1;
                }
                return 0;
              })
              .map((value, index) => (
                <div
                  key={value.id}
                  className='search-list-option'
                  onClick={() => {
                    setCurrentPharmacy(value);
                    setSelectedOption('address');
                    setLastSearchTerm(searchTerm);
                    setSearchTerm('');
                  }}
                >
                  {value.title} ({value.distance.toFixed(2)} km)
                </div>
              ))
          );
        } else {
          setValueList(
            searchResults.data
              .sort(function (a, b) {
                if (a.title < b.title) {
                  return -1;
                }
                if (a.title > b.title) {
                  return 1;
                }
                return 0;
              })
              .map((value, index) => (
                <div
                  key={value.id}
                  className='search-list-option'
                  onClick={() => {
                    setCurrentPharmacy(value);
                    setLastSearchTerm(searchTerm);
                    setSearchTerm('');
                  }}
                >
                  {value.title}
                </div>
              ))
          );
        }
      }
    }
    console.log(searchResults.data);
  }, [searchResults.data, searchTerm]);

  function handleSearchChange(e) {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);

    setFilteredValues(
      sourcePharmacies!.filter((pharmacy) => {
        return selectedOption === 'name'
          ? pharmacy.title.toLowerCase().includes(searchTerm.toLowerCase())
          : pharmacy.location.toLowerCase().includes(searchTerm.toLowerCase());
      })
    );
  }

  const resetSelectedPharmacy = async () => {
    setCurrentPharmacy(undefined);
    setSearchTerm('');
  };

  return (
    <div>
      <ActionButtons resetAllFilters={resetSelectedPharmacy} />
      <div className='pharmacies'>
        <PharmacyInfo pharmacy={currentPharmacy} pharmacyInfo={pharmacyInfo} />
        <div className='pharmacy-select'>
          <div className='pharmacy-select-options'>
            <div className='select-option'>
              <input
                type='radio'
                name='pharmacy'
                id='all=pharmacy'
                checked={selectedType === 'all' ? true : false}
                value={'all'}
                onClick={() => {
                  setSelectedType('all');
                  setSearchTerm('');
                  setCurrentPharmacy(undefined);
                }}
              />
              <label htmlFor='all=pharmacy'>Alle</label>
            </div>
            <div className='select-option'>
              <input
                type='radio'
                name='pharmacy'
                id='one-pharmacy'
                value={'one-pharmacy'}
                onClick={() => {
                  setSearchTerm('');
                  setSelectedType('pharmacy');
                  myRef.current?.scrollIntoView({ behavior: 'smooth' });
                }}
              />
              <label htmlFor='one-pharmacy'>Apotheke wählen</label>
            </div>
          </div>

          <div
            style={selectedType !== 'pharmacy' ? { visibility: 'hidden' } : {}}
          >
            <PharmacySearch
              refProp={myRef}
              activeType={selectedOption}
              setActiveType={setSelectedOption}
              search={searchTerm}
              handleSearchChange={handleSearchChange}
              selectOption={selectedType}
              valueList={valueList}
            />
          </div>
        </div>
      </div>
      <button
        id='apply-button'
        onClick={async () => {
          await applyFilters(
            filter,
            currentPharmacy,
            selectedOption,
            lastSearchTerm
          );
        }}
      >
        Anwenden
      </button>
    </div>
  );
}
