import React from 'react';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

const THC_RANGE = [0, 31];

interface ThcProps {
  thc: [number, number];
  setThc: (thc: [number, number]) => void;
}

export function Thc({ thc, setThc }: ThcProps) {
  // const [thc, setThc] = useState([0, 5]);

  return (
    <div className='filter-box'>
      <p className='filter-box-name'>THC</p>
      <div className='filter-box-value'>{thc[0]}</div>
      <RangeSlider
        id='range-slider-gradient'
        className='margin-lg'
        value={thc}
        min={THC_RANGE[0]}
        max={THC_RANGE[1]}
        onInput={setThc}
      />
      <div className='filter-box-value'>{thc[1]}</div>
    </div>
  );
}
