import React from 'react';
import './SelectedValues.scss';

export default function SelectedValues({ values, setValues }) {
  let selectedValues = values.map((value) => (
    <div
      className='value'
      key={value}
      onClick={() => {
        setValues((prev) => prev.filter((p) => p !== value));
      }}
    >
      <img src='cancel-value.svg' alt='' />
      {value}
    </div>
  ));
  return <div className='selected-values'>{selectedValues}</div>;
}
