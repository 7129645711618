import React from 'react';
import './PageButton.scss';
import { Pharmacy } from '../../pharmacies/Pharmacies';
import PharmacyInfo from '../../pharmacies/pharmacy-info/PharmacyInfo.tsx';
import { FilterI } from '../../../interfaces/filter.ts';

interface PageButtonProps {
  name: string;
  className: string;
  budsInfo?: { budsCount; countryCount; manufacturerCount };
  pharmacyInfo?: { pharmacyCount };
  pharmacy?: Pharmacy;
  filter?: FilterI;
}

export default function PageButton({
  name,
  className,
  budsInfo,
  pharmacyInfo,
  pharmacy,
  filter,
}: PageButtonProps) {
  let text;
  if (budsInfo) {
    text = (
      <p>
        {budsInfo.budsCount} Blüten <br />
        aus {budsInfo.countryCount} Ländern <br /> von{' '}
        {budsInfo.manufacturerCount} Herstellern
        <span className='additional-text'>
          sind in Deutschen Apotheken verfügbar.
        </span>
      </p>
    );
  } else if (pharmacyInfo) {
    if (!pharmacy) {
      text = <p>Apotheken {pharmacyInfo.pharmacyCount}</p>;
    } else {
      text = <PharmacyInfo pharmacy={pharmacy} pharmacyInfo={pharmacyInfo} />;
    }
  } else {
    text = <p>In Entwicklung</p>;
  }

  const isBudsFilterApplied = filter && Object.keys(filter).some(
    (key) => filter[key] !== null && filter[key] !== ''
  );

  const isPharmacyFilterApplied = pharmacy && pharmacy?.id;

  return (
    <div
      className={
        (className === 'bluten' && isBudsFilterApplied) || (className === 'pharmacy' && isPharmacyFilterApplied)
          ? `page-button ${className} active`
          : `page-button ${className}`
      }
    >
      <div className='page-button-name'>
        <h2>{name}</h2>
      </div>
      <div className='page-button-text'>{text}</div>
    </div>
  );
}
