import React, { useEffect, useRef, useState } from 'react';
import DropdownItem from './DropdownItem.tsx';
import './Dropdown.scss';
import SelectedValues from './selected-values/SelectedValues.tsx';

export default function Dropdown({
  name,
  sourceValues,
  sessionValues,
  setSessionValues,
}) {
  const [isActive, setIsActive] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredValues, setFilteredValues] = useState(sourceValues);
  const [selectedValues, setSelectedValues] = useState(sessionValues);

  const myRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSessionValues(selectedValues);
  }, [selectedValues]);

  useEffect(() => {
    setSelectedValues(sessionValues);
    setFilteredValues(sourceValues);
    setSearch('');
  }, [sessionValues, sourceValues]);

  function handleSearchChange(e) {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    setFilteredValues(
      sourceValues.filter((value) =>
        value.value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }

  return !isActive ? (
    <div className='dropdown'>
      <div
        ref={myRef}
        className='dropdown-header'
        onClick={(evt) => {
          setIsActive(!isActive);
          myRef.current!.scrollIntoView({ behavior: 'smooth' });
        }}
      >
        <div className='dropdown-name'>{name}</div>
        <img
          src='arrow.svg'
          alt='Arrow'
          className='dropdown-triangle'
          style={{ transform: 'rotate(180deg' }}
        />
      </div>
      {selectedValues.length > 0 ? (
        <SelectedValues values={selectedValues} setValues={setSelectedValues} />
      ) : null}
    </div>
  ) : (
    <div className='dropdown'>
      <div
        ref={myRef}
        className='dropdown-header'
        onClick={(evt) => {
          setIsActive(!isActive);
          myRef.current!.scrollIntoView({ behavior: 'smooth' });
        }}
      >
        <div className='dropdown-name'>{name}</div>
        <img src='arrow.svg' alt='Arrow' className='dropdown-triangle' />
      </div>
      <div className='dropdown-main'>
        <div className='dropdown-main-search'>
          <img src='search.svg' alt='Search' />
          <input
            type='text'
            name='value-search'
            id='value-search'
            placeholder={`Suche nach ${name}`}
            value={search}
            onChange={handleSearchChange}
          />
        </div>
        <div className='dropdown-list'>
          {filteredValues.map((item, index) => (
            <DropdownItem
              item={item}
              key={index}
              setValues={setSelectedValues}
              isChecked={selectedValues.includes(item.value) ? true : false}
            />
          ))}
        </div>
      </div>

      <SelectedValues values={selectedValues} setValues={setSelectedValues} />
    </div>
  );
}
