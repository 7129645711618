import React from 'react';
import RangeSlider from 'react-range-slider-input';
// import { Filter } from "@/services/filters";
import 'react-range-slider-input/dist/style.css';

// #TODO Write request to server to get range
const CBD_RANGE = [0, 21];

export function Cbd({ cbd, setCbd }) {
  // const [cbd, setCbd] = useState([0, 5]);

  return (
    <div className='filter-box'>
      <p className='filter-box-name'>CBD</p>
      <div className='filter-box-value'>{cbd[0]}</div>
      <RangeSlider
        id='range-slider-gradient'
        className='margin-lg'
        value={cbd}
        min={CBD_RANGE[0]}
        max={CBD_RANGE[1]}
        onInput={setCbd}
      />
      <div className='filter-box-value'>{cbd[1]}</div>
    </div>
  );
}
