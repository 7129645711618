import React, { useEffect, useState } from 'react';
import Dropdown from './dropdown/Dropdown.tsx';
import { getAllTastesList } from '../../../utils/api.ts';

interface Taste {
  value: string;
}

export default function Tastes({ sessionTastes, setTastes }) {
  const [tasteList, setTastesList] = useState<Taste[]>();

  useEffect(() => {
    const fetchTastesList = async () => {
      const taste = await getAllTastesList();
      setTastesList(taste);
    };

    fetchTastesList();
  }, []);

  return tasteList === undefined ? (
    <div></div>
  ) : (
    <Dropdown
      name={'Aromen'}
      sourceValues={tasteList}
      sessionValues={sessionTastes}
      setSessionValues={setTastes}
    ></Dropdown>
  );
}
