import React, { useEffect, useState } from 'react';
import Dropdown from './dropdown/Dropdown.tsx';
import { getAllOriginCountriesList } from '../../../utils/api.ts';

interface OriginCountry {
  value: string;
}

export default function OriginCountries({ sessionOriginCountries, setOriginCountries }) {
  const [originCountriesList, setOriginCountriesList] = useState<OriginCountry[]>();

  useEffect(() => {
    const fetchOriginCountriesList = async () => {
      const originCountries = await getAllOriginCountriesList();
      setOriginCountriesList(originCountries);
    };

    fetchOriginCountriesList();
  }, []);

  return originCountriesList === undefined ? (
    <div></div>
  ) : (
    <Dropdown
      name={'Herkunftsland'}
      sourceValues={originCountriesList}
      sessionValues={sessionOriginCountries}
      setSessionValues={setOriginCountries}
    ></Dropdown>
  );
}
