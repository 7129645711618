import React from 'react';
import './Header.scss';

export function Header() {
  return (
    <div id='header'>
      <div className='header-logos'>
        <div className='header-greensby-logo'>
          <img src='greensby_logo.svg' alt='Greensby Logo' height={16} />
        </div>
        <div className='header-normal'>
          <img src='normal.svg' alt='Normal' />
        </div>
      </div>
    </div>
  );
}
